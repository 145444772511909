import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PoliticaCookies = () => (
  <Layout>
    <SEO title="Política de cookies" />

    <div className="page-legal container">
    	<h1 className="main-title">Política de cookies</h1>

		Versión publicada el 13 de febrero de 2020
		<h2 className="title">Definición y función de las cookies.</h2>

		<p>El sitio web https://www.cocowat.com/ (el “Sitio Web”) utiliza cookies y otros archivos de funcionalidad similar. Los cookies son pequeños ficheros que se descargan en 
		aquellos dispositivos que se utilizan para acceder al Sitio Web. Los cookies permiten a la Compañía, entre otros, almacenar y recuperar información sobre los hábitos de 
		navegación del Usuario y, dependiendo de la información que contengan y de la forma en que utilice el dispositivo para acceder al Sitio Web, pueden utilizarse para reconocer 
		al mismo.</p>
		
		<h2 className="title">Tipología de Cookies y finalidades de uso.</h2>
		<p>Durante la navegación al Sitio Web, se utilizarán diferentes tipologías de cookies, tanto propias como de terceros; tales cookies pueden clasificarse en las siguientes:</p>
		
		<ul>
			<li><strong>Cookies de Rendimiento:</strong> Estas cookies se utilizan para mejorar experiencia de navegación del Usuario y optimizar el funcionamiento del Sitio Web. 
			Las presentes cookies almacenan configuraciones de los Servicios para que el Usuario no tenga que reconfigurarlos cada vez que visita el Sitio Web.</li>
			<li><strong>Cookies de Geolocalización:</strong> Estas cookies se utilizan para almacenar datos de geolocalización del dispositivo utilizado por el Usuario para acceder al Sitio 
			Web para ofrecerte contenidos y servicios más adecuados.</li>
			<li><strong>Cookies Analíticas:</strong> Estas cookies se utilizan para recopilar información de la experiencia de navegación del Usuario en el Sitio Web.</li>
			<li><strong>Cookies de Publicidad:</strong> Estas cookies son utilizadas para recoger información sobre los anuncios mostrados a cada Usuario a través del Sitio Web.</li>
		</ul>

		<p>Asimismo, tales cookies utilizadas en el Sitio Web pueden distinguirse entre cookies propias y de terceros.</p>
		<p>Las cookies propias son aquellas creadas y/o gestionadas por el responsable del Sitio Web. Por otra parte, las cookies de terceros son aquellas creadas y/o gestionadas 
		por prestadores de servicios de análisis, de redes sociales, de personalización y de publicidad ajenos a la Compañía.</p>
		
		<h3>Cookies utilizadas en este sitio web</h3>
		<p>A continuación, se muestra una tabla con las Cookies utilizadas en este sitio web, incorporando un criterio de nivel de intrusividad apoyado en una escala del 1 al 3, en la que:</p>
		
		<ul>
			<li><strong>Nivel 1:</strong> se corresponde con Cookies estrictamente necesarias para la prestación del propio servicio solicitado por el usuario.</li>
			<li><strong>Nivel 2:</strong> se corresponde con Cookies de rendimiento (anónimas) necesarias para el mantenimiento de contenidos y navegación, de las que solo es necesario informar 
			sobre su existencia.</li>
			<li><strong>Nivel 3:</strong> se corresponde con Cookies gestionadas por terceros que permiten el seguimiento del usuario a través de webs de las que Cocowat. no es titular. Normalmente se 
			corresponden con Cookies de publicidad o de analíticas donde se identifica al usuario de manera única y evidente.</li>
		</ul>

		<p>Tabla de cookies utilizadas:</p>
		<table className="table table-bordered">
			<thead>
				<tr>
					<th>Cookie</th>
					<th>Duración</th>
					<th>Tipo</th>
					<th>Propósito</th>
					<th>Intrusividad</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Aviso de cookies</td>
					<td>1 año</td>
					<td>Sesión</td>
					<td>Técnica</td>
					<td>Nivel 2</td>
				</tr>
				<tr>
					<td>Google Analytics</td>
					<td>1 año</td>
					<td>Sesión</td>
					<td>Analítica</td>
					<td>Nivel 2</td>
				</tr>				
			</tbody>			
		</table>
		 
		<h2 className="title">Desactivación o eliminación de cookies.</h2>
		<p>Se permite bloquear, cancelar o eliminar las cookies instaladas en el dispositivo del Usuario mediante la configuración de las opciones del navegador instalado en el 
		dispositivo del mismo. Para el caso de dispositivos móviles, para saber cómo proceder en el navegador de su teléfono móvil, deberá consultar el manual de su terminal.
		 A continuación, a modo de ejemplo, le indicamos cómo desactivar o eliminar cookies en los principales navegadores:</p>
		
		<p>Para desactivar o eliminar las cookies en los distintos navegadores: 
		<a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias" rel="noopener noreferrer" target="_blank">Firefox</a> / 
		<a href="https://support.google.com/chrome/answer/95647?hl=es" rel="noopener noreferrer" target="_blank">Chrome</a> /  
		<a href="https://support.apple.com/es-es/HT201265" rel="noopener noreferrer" target="_blank">Safari</a> / 
		<a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html" rel="noopener noreferrer" target="_blank">Opera</a> / 
		<a href="https://support.microsoft.com/en-us/products/windows" rel="noopener noreferrer" target="_blank">Explorer</a></p>


		<p>Asimismo, el Usuario debe tener en cuenta que algunas características del Contenido del Sitio Web solo están disponible si se permite la instalación de cookies en su navegador. 
		Si decide no aceptar o bloquear determinadas cookies (en función de su finalidad), puede que esto afecte, total o parcialmente, al funcionamiento normal del Sitio Web o que impida 
		el acceso a la navegación de ciertos contenidos.</p>
		
		<h2 className="title">Actualizaciones y cambios en la Política de Cookies.</h2>
		<p>La Compañía puede modificar la presente Política de Cookies en función de cambios legislativos o reglamentarios, o con la finalidad de adaptar la presente policía a 
		instrucciones o directrices dictadas por la Agencia Española de Protección de Datos.</p>
		<p>Cuando se produzcan cambios significativos en esta Política de Cookies se comunicará al Usuario bien mediante un aviso informativo en el Sitio Web o, en su caso, 
		bien a través de correo electrónico a los Usuarios.</p>
		<p>Si como Usuario desea más información sobre qué uso hacemos de las cookies, puede enviarnos un e-mail a info@cocowat.com.</p>


    </div>
  </Layout>
)

export default PoliticaCookies